  
 .view-project-details {
     width: 100%;
     // position: absolute;
     // top: 60%;
     display: flex;
     justify-content: center;
     align-items: flex-start;
     margin-top: 1rem;
     margin-bottom: 1rem;

     .view-project-details-container {
         width: 85%;
         display: flex;
         justify-content: center;
         // flex-direction: column;
         gap: 1.5rem;


         .view-project-detail-flex1 {
             width: 68%;
             display: flex;
             flex-direction: column;
             gap: 1rem;
             border-radius: 10px;

             .view-project-detail-flex1-container {
                 width: 100%;
                 border: 1px solid rgb(181, 178, 178);
                 padding: 1rem;
                 border-radius: 10px;
                 display: flex;
                 flex-direction: column;
                 gap: 1rem;

                 .view-details-heading {
                     display: flex;
                     justify-content: space-between;

                     h3 {
                         font-size: 1.5rem;
                         font-weight: 700;
                     }
                 }

                 .view-details-dated {
                     h6 {
                         font-size: 15px;
                         font-weight: 400;
                         color: grey;
                     }
                 }

                 .view-details-skill {
                     h6 {
                         font-weight: 600;
                         font-size: 18px;
                     }
                 }

                 .view-details-para {
                     color: grey;
                     font-size: 19px;
                 }

             }

             .recentbidfreelancer {
                 width: 100%;
                 // border: 1px solid red;
                 display: flex;
                 flex-direction: column;
                 gap: 1rem;

                 .allrecuiter-content {
                     border: 1px solid rgb(219, 219, 219);
                     display: flex;
                     gap: 1rem;
                     padding: 1rem;
                     border-radius: 3px;
cursor: pointer;
                     .bidfreelancer-image {
                         width: 120px;
                         height: 120px;

                         img {
                             width: 100%;
                             height: 100%;
                             object-fit: cover;
                             border-radius: 5px;
                         }
                     }

                     .view-details-title {
                         h5 {
                             font-size: 15px;
                             font-weight: 500;
                         }

                         h4 {
                             display: flex;
                             align-items: center;
                             gap: 4px;
                             font-size: 15px;
                             font-weight: 500;
                         }

                         p {
                             font-size: 14px;
                             font-weight: 400;
                             color: grey;
                         }
                     }
                 }
             }

         }

         .view-project-detail-flex2 {
             width: 29%;
             border: 1px solid rgb(239, 236, 236);
             padding: 1rem;
             background-color: white;
             border-radius: 10px;
             display: flex;
             flex-direction: column;
             gap: 1rem;

             .view-project-detail-flex2-container {
                 width: 100%;
                 border: 1px solid rgb(186, 185, 185);
                 padding: 1rem;
                 display: flex;
                 flex-direction: column;
                 align-items: center;
                 border-radius: 3px;
                 gap: 1rem;

                 h4 {
                     font-weight: 600;
                     font-size: 20px;
                 }
             }

             h5 {
                 font-size: 18px;
                 font-weight: 600;
             }

             .project-cards {
                 width: 100%;
                 // border: 1px solid green;
                 display: flex;
                 gap: 1rem;
                 flex-direction: column;

                 .allrecuiter-content {
                     width: 100%;
                     border: 1px solid rgb(186, 185, 185);

                     padding: 1rem;
                     border-radius: 12px;

                     

                     .job-title {

                         // color: red;
                         h5 {
                             font-size: 18px;
                             font-weight: 500;
                         }
                     }

                     .job-hostedby {
                         font-size: 13px;
                     }

                     .job-category {
                         font-size: 13px;
                     }

                     .applicant-education-price {
                         font-size: 15px;
                         font-weight: 600;
                         color: green;

                     }

                     .hiretalent-content-about {
                         font-size: 12px;
                         color: grey;
                     }
                 }
             }



         }
     }
 }
 @media (max-width: 575.98px) {
    .view-project-details {
        width: 100%;
        // position: absolute;
        // top: 60%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 1rem;
        margin-bottom: 1rem;
   flex-wrap: wrap;
        .view-project-details-container {
            width: 95%;
            display: flex;
            justify-content: center;
            // flex-direction: column;
            gap: 1.5rem;
            flex-wrap: wrap;
   
   
            .view-project-detail-flex1 {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                border-radius: 10px;
   
                .view-project-detail-flex1-container {
                    width: 100%;
                    border: 1px solid rgb(181, 178, 178);
                    padding: 1rem;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
   
                    .view-details-heading {
                        display: flex;
                        justify-content:flex-start;
                        flex-direction: column;
                        flex-wrap: wrap;
   
                        h3 {
                            font-size: 17px;
                            font-weight: 700;
                        }
                    }
   
                    .view-details-dated {
                        h6 {
                            font-size: 15px;
                            font-weight: 400;
                            color: grey;
                        }
                    }
   
                    .view-details-skill {
                        h6 {
                            font-weight: 600;
                            font-size: 18px;
                        }
                    }
   
                    .view-details-para {
                        color: grey;
                        font-size: 19px;
                    }
   
                }
   
                .recentbidfreelancer {
                    width: 100%;
                    // border: 1px solid red;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
   
                    .allrecuiter-content {
                        border: 1px solid rgb(219, 219, 219);
                        display: flex;
                        gap: 1rem;
                        padding: 1rem;
                        border-radius: 3px;
   
                        .bidfreelancer-image {
                            width: 120px;
                            height: 120px;
   
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 5px;
                            }
                        }
   
                        .view-details-title {
                            h5 {
                                font-size: 15px;
                                font-weight: 500;
                            }
   
                            h4 {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                font-size: 15px;
                                font-weight: 500;
                            }
   
                            p {
                                font-size: 14px;
                                font-weight: 400;
                                color: grey;
                            }
                        }
                    }
                }
   
            }
   
            .view-project-detail-flex2 {
                width: 100%;
                border: 1px solid rgb(239, 236, 236);
                padding: 1rem;
                background-color: white;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                gap: 1rem;
   
                .view-project-detail-flex2-container {
                    width: 100%;
                    border: 1px solid rgb(186, 185, 185);
                    padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 3px;
                    gap: 1rem;
   
                    h4 {
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
   
                h5 {
                    font-size: 18px;
                    font-weight: 600;
                }
   
                .project-cards {
                    width: 100%;
                    // border: 1px solid green;
                    display: flex;
                    gap: 1rem;
                    flex-direction: column;
   
                    .allrecuiter-content {
                        width: 100%;
                        border: 1px solid rgb(186, 185, 185);
   
                        padding: 1rem;
                        border-radius: 12px;
   
                        .flag-project-container {
                            width: 50px;
                            height: 50px;
   
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
   
                        .job-title {
   
                            // color: red;
                            h5 {
                                font-size: 18px;
                                font-weight: 500;
                            }
                        }
   
                        .job-hostedby {
                            font-size: 13px;
                        }
   
                        .job-category {
                            font-size: 13px;
                        }
   
                        .applicant-education-price {
                            font-size: 15px;
                            font-weight: 600;
                            color: green;
   
                        }
   
                        .hiretalent-content-about {
                            font-size: 12px;
                            color: grey;
                        }
                    }
                }
   
   
   
            }
        }
    }
 }