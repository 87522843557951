.headerfirst {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    .headerfirst-inner {
        width: 85%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;

        .headerfirst-inner-flexfirst {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            a {
                text-decoration: none;
                background-color: white;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

                .header-icon {
                    color: black;
                }

            }
        }

        .headerfirst-inner-second {
            color: white;
            display: flex;
            align-items: center;
            gap: 1rem;
            font-family: 'Poppins', sans-serif;
            font-weight: 200;
            font-size: 15px;
        }
    }

}

.header-second {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    z-index: 99999;

    // box-shadow: 5px 5px 10px #7f817d;
    border: 1px solid rgb(240, 236, 236);

    transition: background-color 0.3s ease;

    // border: 3px solid red;
    .headersecond-container {
        width: 85%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 999;

        .headersecond-img {
            width: 20%;

            // border: 2px solid red;
            img {
                // object-fit: cover;
                width: 70px;
                height: 50px;
                // border: 2px solid red;

            }
        }

        .header-rightside {
            width: 80%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            // border: 1px solid black;

            .credits-points-freelancer {

                // padding: 4px 12px;
                // border-radius: 53px;
                a {
                    text-decoration: none;
                    display: flex;
                    gap: 3px;

                    span {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-size: 13px;
                        padding: 0%;
                        margin: 0%;
                        color: black;

                        p {
                            font-size: 13px;
                            padding: 0%;
                            margin: 0%;
                        }
                    }
                }
            }

            .header-hamburger {
                display: none;
            }
            .avatar-profile {
                // border: 2px solid red;
                position: relative;

                .avatar-container {
                    width: 100px;
                    // padding: 1.8rem;
                    position: absolute;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin-top: 20px;
                    // gap: 10px;
                    flex-direction: column;
                    background-color: white;
                    border: 3px solid blue;

                    .dropdown-content {
                        padding: 1rem;
                        display: flex;
                        // justify-content: center;
                        // align-items: center;
                        flex-direction: column;
                        gap: 10px;
                        border: 1px solid grey;
                        cursor: pointer;

                    }
                }
            }
        }
    }

}

.header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: red;
}

.header-red {
    // background-color: #FFFFFF !important;
    background-color: #1e1e1eb7;
    border: none;
    backdrop-filter: blur(6px) !important;

}


.header-mobile {
    display: none;
}

.mini-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;

    .mini-header-container {
        width: 80%;
        display: flex;
        border: 1px solid red;

        ul {
            display: flex;
            gap: 10px;
            list-style: none;

        }
    }
}

@media (max-width: 575.98px) {
    .headerfirst {
        width: 100%;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8vh;

        .headerfirst-inner {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            // padding: 0.5rem;

            .headerfirst-inner-flexfirst {

                display: none;
            }

            .headerfirst-inner-second {
                width: 100%;
                display: flex;
                justify-content: flex-end;


                .header-email {
                    display: block;
                    font-size: 11px;
                    padding-right: 4px;
                }
            }
        }

    }
    .header-red {
        // background-color: #FFFFFF !important;
        background-color: #1e1e1eb7;
        border: none;
        backdrop-filter: blur(6px) !important;
  
    
    }
    .header-fixed {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        background-color: red;
    }
    .header-second {
        width: 100%;
        height: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        z-index: 99999;
//  margin-bottom: 2rem;
        border: 1px solid rgb(240, 236, 236);
    

        transition: background-color 0.3s ease;
        .header-red ,.header-second{
            // background-color: #FFFFFF !important;
            background-color: #1e1e1eb7;
            border: none;
            backdrop-filter: blur(6px) !important;
        
        }
        // border: 3px solid red;
        .headersecond-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 999;
            padding: 1rem;

            .headersecond-img {
                width: 20%;

                // border: 2px solid red;

                img {
                    // object-fit: cover;
                    width: 100%;
                    height: 50px;
                    object-fit: contain;
                    // border: 2px solid red;

                }
            }

            .header-rightside {
                width: 80%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 1px;

                // border: 1px solid black;

                .header-post-project {
                    display: none;
                }
                .post-project-mobile{
                    display: none;
                }

                .credit-header-side {
                    width: 40%;
                    // border: 3px solid green;

                  
                        .become-freelancer-header{
                            display: none;
                     
                       }
                    .credits-points-freelancer {

                        // padding: 4px 12px;
                        // border-radius: 53px;
                        a {
                            text-decoration: none;
                            display: flex;
                            gap: 3px;

                            span {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                font-size: 13px;
                                padding: 0%;
                                margin: 0%;
                                color: black;

                                p {
                                    font-size: 13px;
                                    padding: 0%;
                                    margin: 0%;
                                }
                            }
                        }
                    }
                }

                .header-notification {
                    width: 15%;
                    // border: 1px solid orange;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .header-profile-account {
                    display: none;
                }

                .header-hamburger {
                    width: 15%;
                    display: flex;
                    justify-content: center;
                }

            }
        }

    }



    .additional-content { 
        position: fixed;
        top: 8.1vh;
        left: 40%;
        width: 60%;
        height: 100%;
        background-color: black;
        border-top-left-radius: 12px;
        color: white;
        z-index: 9999;
        overflow-y: auto;
        display: none;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        transition: left 0.3s ease-in-out;

        /* Hide initially */
        .additional-content-inner {
            width: 100%;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            gap: 3rem;

            ul {
                width: 100%;
                list-style-type: none;
                margin: 0%;
                padding: 0%;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
                gap: 10px;

                li {
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                    // border: 1px solid white;
                    padding: 5px;
                    &:nth-child(1){
                        display: flex;
                        
                    }
                    a {

                        text-decoration: none;
                        color: white;
                        font-weight: 400;

                        &:hover {
                            color: red;
                        }
                    }
                }
            }
        }
    }
}