.loading-wrapper {
  width: 100%;
  height: 100vh;
  background-color: rgba(232, 223, 223, 0.537);
    
    
    z-index: 999;
    position: fixed;
    top: 50%;
    left: 50%;
    
     transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

  }