*{
  font-family: "Poppins", sans-serif;
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  font-family: "Poppins", sans-serif;
}

 

 

 
