.buy-points-contianer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 280px;
    margin-top: 2rem;

    // border: 1px solid red;
    .buy-points {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 100%;
        // border: 1px solid blue;
        position: relative;
        border-radius: 12px;

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            border-radius: 12px;

        }

        .freelancer-plan-content {
            position: absolute;
            left: 5%;
            /* Aligns the content to the left */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h6 {
                font-size: 20px;
                color: #ffffff;
            }

            h4 {
                font-size: 31px;
                color: white;
            }

            button {
                outline: none;
                border: none;
                background-color: #ffffff;
                color: black;
                padding: 16px 32px;
                border-radius: 32px;
            }


        }
    }

}

// .chooseplan-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     height: auto;
//     // margin-top: 3rem;
//     margin-bottom: 4rem;
//     // border: 1px solid blue;
//     background: linear-gradient(180deg, #FFDDDD 0%, #FFFFFF 100%);

//     .choose-plan {
//         width: 90%;
//         height: auto;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         flex-wrap: wrap;
//         gap: 1rem;
//         flex-direction: column;
//         border-radius: 30px;
//         padding: 3rem;

//         .choose-plan-heading {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             flex-direction: column;

//             h6 {
//                 color: #1E1E1E;
//             }

//             h3 {
//                 color: #E82D2D;
//                 font-size: 31px;
//                 font-weight: 600;
//             }

//             h5 {
//                 font-size: 20px;
//                 font-weight: 400;
//             }
//         }

//         .choose-plan-content-container {
//             width: 90%;
//             background-color: #FFFFFF80;
//             display: flex;
//             align-items: center;
//             padding: 3rem;
//             gap: 1rem;

//             .choose-plan-content {
//                 width: 33%;
//                 height: auto;
//                 border: 1px solid #FFBFBF;
//                 background-color: #FFFFFF80;

//                 padding: 20px;
//                 border-radius: 16px;
//                 transition: transform 0.3s ease;
//                 overflow: hidden;
//                 display: flex;
//                 flex-direction: column;
//                 gap: 5px;

//                 &:hover {
//                     background-color: #FF3131;
//                     color: white;
//                     transform: scale(1.2);
//                     overflow: hidden;
//                     z-index: 3;

//                     .recommended-badge-container {
//                         width: 100%;

//                         height: 5vh;
//                         color: #FEFEFE;
//                         background-color: #FF3131;
//                         display: flex;
//                         justify-content: flex-start;
//                         align-items: center;



//                     }

//                     .save-percentage-freelancer {
//                         width: 100%;
//                         display: flex;
//                         align-items: center;
//                         gap: 5px;
//                         // border: 1px solid green;
//                         align-content: center;
//                         justify-content: flex-start;


//                         h5 {
//                             text-decoration: line-through;
//                             color: white;
//                             font-size: 14px;
//                             padding: 0%;
//                             margin: 0%;
//                         }

//                         .save-amount-per {
//                             border: 2px solid white;
//                             font-size: 12px;
//                             font-style: 500;
//                             color: white;
//                             border-radius: 32px;
//                             padding: 4px 12px;

//                         }
//                     }

//                     h3 {
//                         font-size: 24px;
//                         font-weight: 700;
//                         margin-top: 8px;

//                         .rupee-sign {
//                             font-size: 26px;
//                             font-weight: 800;
//                             // color: red;
//                         }

//                         .plan-per-months {
//                             font-weight: 500;
//                             font-size: 14px;
//                             color: white;
//                         }
//                     }

//                     .excluding {
//                         width: 100%;
//                         height: 60px;
//                         color: white;
//                         font-size: 13px;
//                         font-weight: 600;
//                     }

//                     .plan-features {
//                         list-style-type: none;
//                         // border: 1px solid red;

//                         .feature-included {
//                             list-style: none;
//                         }
//                     }

//                     .choose-plan-button {
//                         width: 100%;

//                         button {

//                             width: 100%;
//                             padding: 9px 18px;
//                             background-color: #1E1E1E;
//                             border: 1px solid black;
//                             outline: none;
//                             color: white;
//                             border-radius: 32px;
//                         }
//                     }

//                 }

//                 .recommended-badge-container {
//                     width: 100%;

//                     height: 5vh;
//                     color: black;
//                     background-color: transparent;
//                     display: flex;
//                     justify-content: flex-start;
//                     align-items: center;



//                 }

//                 .recommended-badge-container.Gold {


//                     /* Gold color for Platinum */
//                     h5 {
//                         width: 60%;
//                         color: #000000;
//                         padding: 10px;
//                         background-color: white;
//                         border: 1px solid #FF3131;

//                         font-size: 12px;
//                         border-radius: 32px;

//                         font-weight: 600;
//                         color: #FF3131;
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
//                     }


//                 }

//                 .save-percentage-freelancer {
//                     width: 100%;
//                     display: flex;
//                     align-items: center;
//                     gap: 5px;
//                     // border: 1px solid green;
//                     align-content: center;
//                     justify-content: flex-start;


//                     h5 {
//                         text-decoration: line-through;
//                         color: #FF5A5A;
//                         font-size: 14px;
//                         padding: 0%;
//                         margin: 0%;
//                     }

//                     .save-amount-per {
//                         border: 2px solid #FF3131;
//                         font-size: 12px;
//                         font-style: 500;
//                         color: #FF3131;
//                         border-radius: 32px;
//                         padding: 4px 12px;

//                     }
//                 }

//                 h3 {
//                     font-size: 24px;
//                     font-weight: 700;
//                     margin-top: 8px;

//                     .rupee-sign {
//                         font-size: 26px;
//                         font-weight: 800;
//                         // color: red;
//                     }

//                     .plan-per-months {
//                         font-weight: 500;
//                         font-size: 14px;
//                         color: #686868;
//                     }
//                 }

//                 .choose-plan-month {
//                     font-size: 22px;
//                     font-weight: 600;

//                 }

//                 .plan-features {
//                     width: 100%;
//                     // border: 1px solid green;
//                     list-style: none;
//                     padding: 0%;

//                     display: flex;
//                     flex-direction: column;
//                     gap: 8px;

//                     li {
//                         font-size: 15 px;
//                         display: flex;
//                         align-items: center;
//                         gap: 15px;

//                         .green-checkbox {
//                             color: green;

//                             .green-checbox-icon {
//                                 font-size: 22px;
//                             }

//                         }

//                         .red-checkbox-icon {
//                             font-size: 22px;
//                             color: red;
//                         }
//                     }

//                 }

//                 .excluding {
//                     width: 100%;
//                     height: auto;
//                     color: #686868;
//                     font-size: 13px;
//                     font-weight: 600;
//                 }

//                 .choose-plan-button {
//                     width: 100%;

//                     button {

//                         width: 100%;
//                         padding: 9px 18px;
//                         background-color: #FEFEFE;
//                         border: 1px solid #1E1E1E;
//                         outline: none;
//                         border-radius: 32px;
//                     }
//                 }
//             }
//         }

//     }
// }

.chooseplan-container-non-it {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 3rem;
    margin-bottom: 4rem;
    // border: 1px solid blue;
    background: linear-gradient(180deg, #5dcde6 0%, #FFFFFF 100%);

    .choose-plan-non-it {
        width: 90%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        flex-direction: column;
        border-radius: 30px;
        padding: 3rem;

        .choose-plan-heading {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h6 {
                color: #1E1E1E;
            }

            h3 {
                color: #E82D2D;
                font-size: 31px;
                font-weight: 600;
            }

            h5 {
                font-size: 20px;
                font-weight: 400;
            }
        }

        .choose-plan-content-container-non-it {
            width: 90%;
            background-color: #FFFFFF80;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3rem;
            // border: 2px solid green;
            gap: 1rem;

            .choose-plan-content {
                width: 33%;
                height: auto;
                border: 1px solid #FFBFBF;
                background-color: #FFFFFF80;

                padding: 20px;
                border-radius: 16px;
                transition: transform 0.3s ease;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 5px;

                &:hover {
                    background-color: #FF3131;
                    color: white;
                    transform: scale(1.1);
                    overflow: hidden;
                    z-index: 3;

                    .recommended-badge-container {
                        width: 100%;

                        height: 5vh;
                        color: #FEFEFE;
                        background-color: #FF3131;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;



                    }

                    .save-percentage-freelancer {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        // border: 1px solid green;
                        align-content: center;
                        justify-content: flex-end;


                        h5 {
                            text-decoration: none;
                            color: white;
                            font-size: 14px;
                            padding: 0%;
                            margin: 0%;
                        }

                        .save-amount-per {
                            border: 2px solid white;
                            font-size: 12px;
                            font-style: 500;
                            color: white;
                            border-radius: 32px;
                            padding: 4px 12px;

                        }
                    }

                    h3 {
                        font-size: 24px;
                        font-weight: 700;
                        margin-top: 8px;

                        .rupee-sign {
                            font-size: 26px;
                            font-weight: 800;
                            // color: red;
                        }

                        .plan-per-months {
                            font-weight: 500;
                            font-size: 14px;
                            color: white;
                        }
                    }

                    .excluding {
                        width: 100%;
                        height: 60px;
                        color: white;
                        font-size: 13px;
                        font-weight: 600;
                    }

                    .plan-features {
                        list-style-type: none;
                        // border: 1px solid red;

                        .feature-included {
                            list-style: none;
                            
                        }
                        li {
                             
    
                            .red-checkbox-icon {
                                font-size: 22px;
                                color: white;
                            }
                        }
                    }

                    .choose-plan-button {
                        width: 100%;

                        button {

                            width: 100%;
                            padding: 9px 18px;
                            background-color: #1E1E1E;
                            border: 1px solid black;
                            outline: none;
                            color: white;
                            border-radius: 32px;
                        }
                    }

                }

                .recommended-badge-container {
                    width: 100%;

                    height: 5vh;
                    color: black;
                    background-color: 1px solid red;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;



                }

               

                .save-percentage-freelancer {
                    width:100%;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    // border: 1px solid green;
                    // background-color:#FF5A5A ;
                    align-content: center;
                    justify-content: flex-end;


                    h5 {
                        text-decoration: none;
                        color: #FF5A5A;
                        font-size: 14px;
                        padding: 0%;
                        margin: 0%;
                    }

                    .save-amount-per {
                        border: 2px solid #FF3131;
                        font-size: 12px;
                        font-style: 500;
                        color: #FF3131;
                        border-radius: 32px;
                        padding: 4px 12px;

                    }
                }

                h3 {
                    font-size: 24px;
                    font-weight: 700;
                    margin-top: 8px;

                    .rupee-sign {
                        font-size: 26px;
                        font-weight: 800;
                        // color: red;
                    }

                    .plan-per-months {
                        font-weight: 500;
                        font-size: 14px;
                        color: #686868;
                    }
                }

                .choose-plan-month {
                    font-size: 22px;
                    font-weight: 600;

                }

                .plan-features {
                    width: 100%;
                    // border: 1px solid green;
                    list-style: none;
                    padding: 0%;

                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    li {
                        font-size: 15 px;
                        display: flex;
                        align-items: center;
                        gap: 15px;

                        .green-checkbox {
                            color: green;

                            .green-checbox-icon {
                                font-size: 22px;
                            }

                        }

                        .red-checkbox-icon {
                            font-size: 22px;
                            color: red;
                        }
                    }

                }

                .excluding {
                    width: 100%;
                    height: auto;
                    color: #686868;
                    font-size: 13px;
                    font-weight: 600;
                }

                .choose-plan-button {
                    width: 100%;

                    button {

                        width: 100%;
                        padding: 9px 18px;
                        background-color: #FEFEFE;
                        border: 1px solid #1E1E1E;
                        outline: none;
                        border-radius: 32px;
                    }
                }
            }
        }

    }
}

.subdscribe-today {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .subscribe-today-container {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h3 {
            font-size: 28px;
            font-weight: 600;
        }

        p {
            font-size: 17px;
            font-weight: 400;
            color: #666666;
        }
    }
}

.how-it-works {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .how-it-works-container {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        h1 {
            font-size: 26px;
            font-weight: 600;
        }

        .how-it-works-section {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;

            .how-it-works-content {
                width: 24%;
                height: 256px;

                border-radius: 12px;
                border: 1px solid #DEDEDE;
                background-color: #F8F8F8;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 1rem;

                h6 {
                    font-size: 20px;
                    font-weight: 500;
                    color: #4B4B4B;
                }

                p {
                    font-size: 15px;
                    color: #989898;
                }
            }
        }
    }
}

.why-subscribe {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .why-subscribe-container {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        h4 {
            font-size: 28px;
            font-weight: 600;
        }

        p {
            font-size: 15px;
            color: #666666;
        }
    }
}

.faq-seaction-credit-plan {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .faq-section-inner-subscribe-plan {

        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem;

        h4 {
            font-size: 25px;
            font-weight: 600;
        }

        .accordion {
            width: 100%;
            box-shadow: none;
            border: none;
            background-color: white;

            .accordion-item {
                box-shadow: none;
                border: none;
                // border-bottom: 2px solid #C3E8FF;
                background-color: white;

                .accordion-header {
                    box-shadow: none;
                    border-bottom: 3px solid grey;

                    button {
                        background-color: white;
                        border: none;
                    }

                }

            }
        }
    }
}

.dialog-paper {
    width: 45%;
    border-radius: 12px;

    // background-color: green;
    // border: 2px solid greenyellow;
    .plan-model-top-content {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #E9E9E9;
        margin: 5px auto;

    }

    .plan-summary {
        width: 90%;

        display: flex;
        justify-content: center;
        margin: 5px auto;

        .payment-details {
            width: 40%;

            h6 {
                font-size: 12px;
                font-weight: 400;
                color: #686868;
            }

            h5 {
                font-size: 16px;
                font-weight: 600;
                color: #1E1E1E;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: #686868;
            }
        }

        .chooseplan-summary {


            width: 60%;
            border: 2px solid lightgray;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 1rem;
            border-radius: 10px;


            .chooseplan-summary-inner {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .price-plan {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h6 {
                        margin: 0;
                        font-weight: 400;
                        color: #4B4B4B;

                    }

                    span {
                        margin-left: 10px;
                        color: black;
                    }

                    // input {
                    //     width: 100%;
                    //     border: 1px solid lightgrey;
                    //     border-radius: 30px;
                    //     padding: 6px 12px;
                    //     outline: none;
                    // }
                    .coupon-verify {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 10px;
                        border: 1px solid lightgrey;
                        border-radius: 30px;

                        input {
                            width: 68%;
                            outline: none;
                            padding: 10px;
                            border-radius: 30px;
                            border: none;


                        }

                        button {
                            width: 28%;
                            border: 1px solid black;
                            background-color: black;
                            color: white;
                            padding: 6px;
                            border-radius: 22px;
                            font-size: 14px;
                            margin-right: 5px;

                        }

                    }


                }

                .plan-sub-button {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    // background-color: orange;
                    button {
                        width: 30%;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        background-color: white;
                        color: red;

                    }
                }

                .summary-button {
                    width: 100%;
                    margin-top: 2rem;

                    button {
                        width: 100%;
                        background-color: #FFEAEA;
                        font-weight: 400;
                        box-shadow: none;
                        color: #FF5A5A;
                        font-size: 6px 12px;
                        border: 1px solid #FF5A5A;
                        border-radius: 30px;
                    }
                }
            }
        }

    }
}

@media (max-width: 575.98px) {
    .buy-points-contianer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
        margin-top: 2rem;

        // border: 1px solid red;
        .buy-points {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90%;
            height: 100%;
            // border: 1px solid blue;
            position: relative;
            border-radius: 12px;

            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
                border-radius: 12px;

            }

            .freelancer-plan-content {
                position: absolute;
                left: 5%;
                /* Aligns the content to the left */
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                h6 {
                    font-size: 15px;
                    color: #ffffff;
                }

                h4 {
                    font-size: 13px;
                    color: white;
                }

                button {
                    outline: none;
                    border: none;
                    background-color: #ffffff;
                    color: black;
                    padding: 6px 12px;
                    border-radius: 32px;
                    font-size: 15px;
                }


            }
        }

    }

    // .chooseplan-container {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     width: 100%;
    //     height: auto;
    //     margin-top: 1px;
    //     margin-bottom: 4rem;
    //     // border: 1px solid blue;
    //     background: linear-gradient(180deg, #FFDDDD 0%, #FFFFFF 100%);

    //     .choose-plan {
    //         width: 98%;
    //         height: auto;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         flex-wrap: wrap;
    //         gap: 1rem;
    //         flex-direction: column;
    //         border-radius: 30px;
    //         padding: 10px;

    //         .choose-plan-heading {
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //             flex-direction: column;

    //             h6 {
    //                 color: #1E1E1E;
    //             }

    //             h3 {
    //                 color: #E82D2D;
    //                 font-size: 18px;
    //                 font-weight: 600;
    //                 text-align: center;

    //             }

    //             h5 {
    //                 font-size: 14px;
    //                 font-weight: 400;
    //                 text-align: center;
    //             }
    //         }

    //         .choose-plan-content-container {
    //             width: 100%;
    //             background-color: #FFFFFF80;
    //             display: flex;
    //             align-items: center;
    //             flex-wrap: wrap;
    //             padding: 1rem;
    //             gap: 1rem;

    //             .choose-plan-content {
    //                 width: 100%;
    //                 height: auto;
    //                 border: 1px solid #FFBFBF;
    //                 background-color: #FFFFFF80;

    //                 padding: 20px;
    //                 border-radius: 16px;
    //                 transition: transform 0.3s ease;
    //                 overflow: hidden;
    //                 display: flex;
    //                 flex-direction: column;
    //                 gap: 5px;

    //                 &:hover {
    //                     background-color: #FF3131;
    //                     color: white;
    //                     transform: scale(1.2);
    //                     overflow: hidden;
    //                     z-index: 3;

    //                     .recommended-badge-container {
    //                         width: 100%;

    //                         height: 5vh;
    //                         color: #FEFEFE;
    //                         background-color: #FF3131;
    //                         display: flex;
    //                         justify-content: flex-end;
    //                         align-items: center;



    //                     }

    //                     h3 {
    //                         font-size: 24px;
    //                         font-weight: 700;
    //                         margin-top: 8px;

    //                         .rupee-sign {
    //                             font-size: 26px;
    //                             font-weight: 800;
    //                             // color: red;
    //                         }

    //                         .plan-per-months {
    //                             font-weight: 500;
    //                             font-size: 14px;
    //                             color: white;
    //                         }
    //                     }

    //                     .excluding {
    //                         width: 100%;
    //                         height: 60px;
    //                         color: white;
    //                         font-size: 13px;
    //                         font-weight: 600;
    //                     }

    //                     .choose-plan-button {
    //                         width: 100%;

    //                         button {

    //                             width: 100%;
    //                             padding: 9px 18px;
    //                             background-color: #1E1E1E;
    //                             border: 1px solid black;
    //                             outline: none;
    //                             color: white;
    //                             border-radius: 32px;
    //                         }
    //                     }

    //                 }

    //                 .recommended-badge-container {
    //                     width: 100%;

    //                     height: 5vh;
    //                     color: #FEFEFE;
    //                     background-color: white;
    //                     display: flex;
    //                     justify-content: flex-end;
    //                     align-items: center;



    //                 }

    //                 .recommended-badge-container.platinum {


    //                     /* Gold color for Platinum */
    //                     h5 {
    //                         width: 60%;
    //                         color: #000000;
    //                         padding: 10px;
    //                         background-color: white;
    //                         border: 1px solid #FF3131;

    //                         font-size: 12px;
    //                         border-radius: 32px;

    //                         font-weight: 600;
    //                         color: #FF3131;
    //                         display: flex;
    //                         align-items: center;
    //                         justify-content: center;
    //                     }


    //                 }

    //                 h3 {
    //                     font-size: 24px;
    //                     font-weight: 700;
    //                     margin-top: 8px;

    //                     .rupee-sign {
    //                         font-size: 26px;
    //                         font-weight: 800;
    //                         // color: red;
    //                     }

    //                     .plan-per-months {
    //                         font-weight: 500;
    //                         font-size: 14px;
    //                         color: #686868;
    //                     }
    //                 }

    //                 .choose-plan-month {
    //                     font-size: 22px;
    //                     font-weight: 600;

    //                 }

    //                 .excluding {
    //                     width: 100%;
    //                     height: 60px;
    //                     color: #686868;
    //                     font-size: 13px;
    //                     font-weight: 600;
    //                 }

    //                 .choose-plan-button {
    //                     width: 100%;

    //                     button {

    //                         width: 100%;
    //                         padding: 9px 18px;
    //                         background-color: #FEFEFE;
    //                         border: 1px solid #1E1E1E;
    //                         outline: none;
    //                         border-radius: 32px;
    //                     }
    //                 }
    //             }
    //         }

    //     }
    // }

    .chooseplan-container-non-it {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        margin-top: 3rem;
        margin-bottom: 4rem;
        // border: 1px solid blue;
        background: linear-gradient(180deg, #5dcde6 0%, #FFFFFF 100%);

        .choose-plan-non-it {
            width: 95%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;
            flex-direction: column;
            border-radius: 30px;
            padding: 10px;

            .choose-plan-heading {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex-wrap: wrap;

                h6 {
                    color: #1E1E1E;
                }

                h3 {
                    color: #E82D2D;
                    font-size: 20px;
                    font-weight: 600;
                    text-align: center;
                }

                h5 {
                    font-size: 16px;
                    font-weight: 400;
                    text-align: center;
                }
            }

            .choose-plan-content-container-non-it {
                width: 100%;
                background-color: #FFFFFF80;
                display: flex;
                align-items: center;
                padding: 10px;
                gap: 1rem;
                flex-wrap: wrap;

                .choose-plan-content {
                    width: 100%;
                    height: auto;
                    // border: 1px solid #FFBFBF;
                    background-color: #FFFFFF80;

                    padding: 20px;
                    border-radius: 16px;
                    transition: transform 0.3s ease;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    &:hover {
                        background-color: #FF3131;
                        color: white;
                        transform: scale(1.1);
                        overflow: hidden;
                        z-index: 3;

                        .recommended-badge-container {
                            width: 100%;

                            height: 5vh;
                            color: #FEFEFE;
                            background-color: #FF3131;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;



                        }

                        .save-percentage-freelancer {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            // border: 1px solid green;
                            align-content: center;
                            justify-content: flex-end;


                            h5 {
                                text-decoration: none;
                                color: white;
                                font-size: 14px;
                                padding: 0%;
                                margin: 0%;
                            }

                            .save-amount-per {
                                border: 2px solid white;
                                font-size: 12px;
                                font-style: 500;
                                color: white;
                                border-radius: 32px;
                                padding: 4px 12px;

                            }
                        }

                        h3 {
                            font-size: 24px;
                            font-weight: 700;
                            margin-top: 8px;

                            .rupee-sign {
                                font-size: 26px;
                                font-weight: 800;
                                // color: red;
                            }

                            .plan-per-months {
                                font-weight: 500;
                                font-size: 14px;
                                color: white;
                            }
                        }

                        .excluding {
                            width: 100%;
                            height: 60px;
                            color: white;
                            font-size: 13px;
                            font-weight: 600;
                        }

                        .plan-features {
                            list-style-type: none;
                            // border: 1px solid red;

                            .feature-included {
                                list-style: none;
                            }
                        }

                        .choose-plan-button {
                            width: 100%;

                            button {

                                width: 100%;
                                padding: 9px 18px;
                                background-color: #1E1E1E;
                                border: 1px solid black;
                                outline: none;
                                color: white;
                                border-radius: 32px;
                            }
                        }

                    }

                    .recommended-badge-container {
                        width: 100%;

                        height: 5vh;
                        color: black;
                        background-color: transparent;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;



                    }

                    .recommended-badge-container.Silver {


                        /* Gold color for Platinum */
                        h5 {
                            width: 60%;
                            color: #000000;
                            padding: 10px;
                            background-color: white;
                            border: 1px solid #FF3131;

                            font-size: 12px;
                            border-radius: 32px;

                            font-weight: 600;
                            color: #FF3131;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }


                    }

                    .save-percentage-freelancer {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        // border: 1px solid green;
                        align-content: center;
                        justify-content: flex-end;


                        h5 {
                            text-decoration: none;
                            color: #FF5A5A;
                            font-size: 14px;
                            padding: 0%;
                            margin: 0%;
                        }

                        .save-amount-per {
                            border: 2px solid #FF3131;
                            font-size: 12px;
                            font-style: 500;
                            color: #FF3131;
                            border-radius: 32px;
                            padding: 4px 12px;

                        }
                    }

                    h3 {
                        font-size: 24px;
                        font-weight: 700;
                        margin-top: 8px;

                        .rupee-sign {
                            font-size: 26px;
                            font-weight: 800;
                            // color: red;
                        }

                        .plan-per-months {
                            font-weight: 500;
                            font-size: 14px;
                            color: #686868;
                        }
                    }

                    .choose-plan-month {
                        font-size: 22px;
                        font-weight: 600;

                    }

                    .plan-features {
                        width: 100%;
                        // border: 1px solid green;
                        list-style: none;
                        padding: 0%;

                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        li {
                            font-size: 15 px;
                            display: flex;
                            align-items: center;
                            gap: 15px;

                            .green-checkbox {
                                color: green;

                                .green-checbox-icon {
                                    font-size: 22px;
                                }

                            }

                            .red-checkbox-icon {
                                font-size: 22px;
                                color: red;
                            }
                        }

                    }

                    .excluding {
                        width: 100%;
                        height: auto;
                        color: #686868;
                        font-size: 13px;
                        font-weight: 600;
                    }

                    .choose-plan-button {
                        width: 100%;

                        button {

                            width: 100%;
                            padding: 9px 18px;
                            background-color: #FEFEFE;
                            border: 1px solid #1E1E1E;
                            outline: none;
                            border-radius: 32px;
                        }
                    }
                }
            }

        }
    }

    .dialog-plan-modelss,
    .dialog-paper {
        border-radius: 12px;
        width: 100%;

        .plan-model-top-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            border-bottom: 1px solid #E9E9E9;
            margin: 5px auto;

        }

        .plan-summary {
            width: 100%;

            display: flex;
            justify-content: center;
            margin: 2px auto;
            flex-wrap: wrap;

            .payment-details {
                width: 100%;

                h6 {
                    font-size: 12px;
                    font-weight: 400;
                    color: #686868;
                }

                h5 {
                    font-size: 14px;
                    font-weight: 600;
                    color: #1E1E1E;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #686868;
                }
            }

            .chooseplan-summary {


                width: 100%;
                border: 2px solid lightgray;
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 1rem;
                border-radius: 10px;


                .chooseplan-summary-inner {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .price-plan {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h6 {
                            margin: 0;
                            font-weight: 400;
                            color: #4B4B4B;
                            font-size: 14px;

                        }

                        span {
                            margin-left: 10px;
                            color: black;
                            font-size: 15px;

                        }

                        // input {
                        //     width: 100%;
                        //     border: 1px solid lightgrey;
                        //     border-radius: 30px;
                        //     padding: 6px 12px;
                        //     outline: none;
                        // }
                        .coupon-verify {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 10px;
                            border: 1px solid lightgrey;
                            border-radius: 30px;

                            input {
                                width: 68%;
                                outline: none;
                                padding: 10px;
                                border-radius: 30px;
                                border: none;
                                font-size: 13px;


                            }

                            button {
                                width: 28%;
                                border: 1px solid black;
                                background-color: black;
                                color: white;
                                padding: 6px;
                                border-radius: 22px;
                                font-size: 12px;
                                margin-right: 5px;

                            }

                        }


                    }

                    .summary-button {
                        width: 100%;
                        margin-top: 2rem;

                        button {
                            width: 100%;
                            background-color: #FFEAEA;
                            font-weight: 400;
                            box-shadow: none;
                            color: #FF5A5A;
                            font-size: 6px 12px;
                            border: 1px solid #FF5A5A;
                            border-radius: 30px;
                        }
                    }
                }
            }

        }
    }

    .subdscribe-today {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .subscribe-today-container {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            h3 {
                font-size: 18px;
                font-weight: 600;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    .how-it-works {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        .how-it-works-container {
            width: 90%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;

            h1 {
                font-size: 19px;
                font-weight: 600;
            }

            .how-it-works-section {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;
                flex-wrap: wrap;

                .how-it-works-content {
                    width: 100%;
                    height: 256px;

                    border-radius: 12px;
                    border: 1px solid #DEDEDE;
                    background-color: #F8F8F8;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    padding: 1rem;

                    h6 {
                        font-size: 20px;
                        font-weight: 500;
                        color: #4B4B4B;
                    }

                    p {
                        font-size: 15px;
                        color: #989898;
                    }
                }
            }
        }
    }

    .why-subscribe {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        .why-subscribe-container {
            width: 90%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;

            h4 {
                font-size: 18px;
                font-weight: 600;
            }

            p {
                font-size: 14px;
                color: #666666;
            }
        }
    }

    .faq-seaction-credit-plan {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .faq-section-inner-subscribe-plan {

            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;

            h4 {
                font-size: 19px;
                font-weight: 600;
            }

            .accordion {
                width: 100%;
                box-shadow: none;
                border: none;
                background-color: white;

                .accordion-item {
                    box-shadow: none;
                    border: none;
                    // border-bottom: 2px solid #C3E8FF;
                    background-color: white;

                    .accordion-header {
                        box-shadow: none;
                        border-bottom: 3px solid grey;

                        button {
                            background-color: white;
                            border: none;
                        }

                    }

                }
            }
        }
    }
}