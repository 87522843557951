#exampleModal {
    width: 600px;
    border-radius: 10px;
    background-color: white;

    .modal-body {
        width: 100%;
        height: max-content;
        border-radius: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 1.5rem 3.5rem;
        width: 600px;
        border-radius: 20px;


        .close_btn_container {
            width: calc(100% + 3.5rem);
            height: max-content;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            // background-color: orange;
            padding: 0;

            button {
                border: none;
                outline: none;
                background: transparent;
                font-size: 32px;
                margin-top: -15px;
                padding: 0;
                // background-color: red;
            }
        }

        .heading_container {
            width: 100%;
            height: max-content;
            margin-top: 0px;
            align-self: flex-start;
            // background-color: orange;

            p {
                margin: 0;
                padding: 0;
                color: #000;
                font-size: 22.885px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .detail_form {
            width: 100%;
            height: max-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-top: 1.5rem;

            input {
                width: 100%;
                border: none;
                outline: none;
                border-bottom: 2px solid #FF3131;
                margin-bottom: 1.5rem;
                padding: 0.5rem 0.2rem;
            }
            select{
                width: 100%;
                border: none;
                outline: none;
                border-bottom: 2px solid #FF3131;
                margin-bottom: 1.5rem;
                padding: 0.5rem 0.2rem;
                color: grey; 
                box-shadow: none; 
            }

            input[type="submit"] {
                padding: 12px 42px;
                border-radius: 10.676px;
                background: #FF3131;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                width: 35%;
                align-self: flex-start;
            }
        }
    }
}

@media (max-width: 575.98px) {
    #exampleModal {
        width: 100% !important;
        border-radius: 10px;
        background-color: white;
    
        .modal-body {
            width: 100% !important;
            height: max-content;
            border-radius: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding: 1.5rem 1.5rem !important;
            width: 100%;
            border-radius: 20px;
    
    
            .close_btn_container {
                width: calc(100% + 0rem) !important;
                height: max-content;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                // background-color: orange;
                padding: 0;
    
                button {
                    border: none;
                    outline: none;
                    background: transparent;
                    font-size: 32px;
                    margin-top: -15px;
                    padding: 0;
                    // background-color: red;
                }
            }
    
            .heading_container {
                width: 100%;
                height: max-content;
                margin-top: 0px;
                align-self: flex-start;
                // background-color: orange;
    
                p {
                    margin: 0;
                    padding: 0;
                    color: #000;
                    font-size: 18.885px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
    
            .detail_form {
                width: 100% !important;
                height: max-content;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start !important;
                margin-top: 1.5rem;
    
                input {
                    width: 100% !important;
                    border: none;
                    outline: none;
                    border-bottom: 2px solid #FF3131;
                    margin-bottom: 1.5rem;
                    padding: 0.5rem 0.2rem;
                }
    
                input[type="submit"] {
                    padding: 8px 0px !important;
                    border-radius: 10.676px;
                    background: #19b01f;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    width: 40% !important;
                    align-self: flex-start;
                }
            }
        }
    }
}