.success-payment{
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .success-payment-inner{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .payment-icon-contianer{
            .payment-icon{
                font-size: 100px;
                color: green;
            }
        }
    }
}