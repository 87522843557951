.global-projectpost {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .global-project-post-container {
        width: 100%;
        display: flex;
        gap: 10px;

        .global-flex1 {
            width: 60%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 2rem;
            gap: 3rem;

            // border: 1px solid red;
            .post-project-logo {
                width: 80%;
            }

            .project-post-heading {
                width: 80%;

                // border: 1px solid green;

                h1 {
                    font-size: 40px;
                    font-weight: 600;
                    // border: 1px solid green;
                }
            }

            .form-content {
                width: 80%;

                // border: 1px solid red;
                .project-posting-container {
                    width: 100%;
                    // border: 1px solid red;

                    h2 {
                        font-size: 22px;
                    }

                    label {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        font-weight: 600;
                        font-size: 18px;
                        margin-top: 1rem;
                        color: "red";

                        input {
                            border-radius: 4px;
                            padding: 8px;
                            outline: none;
                            border: 1px solid grey;
                        }

                        textarea {
                            padding: 8px;
                            font-size: 15px;
                        }
                    }


                }

                .project-posting-category-container {
                    width: 100%;
                    display: flex;
                    // flex-direction: column;
                    gap: 1rem;
                    flex-wrap: wrap;

                    .project-posting-category {
                        width: 48%;

                        label {
                            font-weight: 600;
                            font-size: 18px;
                        }

                        select {
                            border-radius: 4px;
                            padding: 8px;
                            outline: none;
                            border: 1px solid grey;
                        }
                    }
                }

                .project-posting-skills-container {
                    width: 100%;

                    .project-posting-skill {

                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        gap: 8px;
                        width: 100%;

                        h4 {
                            font-size: 18px;
                        }

                        .skill-add-container {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            input {
                                width: 75%;
                                border-radius: 4px;
                                padding: 8px;
                                outline: none;
                                border: 1px solid grey;
                            }

                            .skill-expertise-button {
                                width: 22%;

                            }
                        }


                    }

                    h5 {
                        margin-top: 1rem;
                        font-size: 13px;
                        color: grey;
                    }

                    .skill-expertise-data {
                        display: flex;
                        width: 100%;
                        gap: 16px;
                        margin-top: 10px;
                        flex-wrap: wrap;

                        // border: 1px solid red;
                        .software-known-button-conatiner {
                            display: flex;
                            position: relative;



                            .software-konw-icon {
                                position: absolute;
                                color: red;
                                right: -5px;
                                top: -5px;
                                font-size: 15px;
                            }
                        }
                    }
                }

                .pay-layout {
                    // border: 1px solid red;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .pay-layout-inner {
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        gap: 1rem;


                        .pay-hour {
                            width: 48%;
                            padding: 1rem;
                            border: 1px solid rgb(224, 215, 215);
                            height: 150px;
                            border-radius: 5px;
                            background-color: white;
                            transition: background-color 0.3s;

                            h6 {
                                font-weight: 600;
                            }

                            p {
                                font-size: 15px;
                            }

                            &:hover {

                                box-shadow: 0 1px 10px #12151b40;
                            }

                            &.selected {
                                border: 2px solid red;
                                border-color: red;
                                /* Change background color when selected */
                            }
                        }


                    }

                    .toggle-budget {
                        // border: 1px solid green;
                        display: flex;
                        flex-direction: column;

                        gap: 1rem;

                        .select-main-heading {
                            font-weight: 600;
                            font-size: 18px;
                        }

                        .per-hour-budget {
                            width: 100%;
                            display: flex;
                            gap: 1rem;
                            flex-wrap: wrap;

                            .custom-budget {
                                // border: 1px solid red;
                                margin: 0;
                                width: 100%;

                                p {
                                    font-weight: 600;
                                }

                                input {
                                    // width: 100%;
                                    outline: none;
                                    // border: 1px solid grey;
                                    // border-radius: 5px;
                                    padding: 0.8rem;
                                }
                            }
                        }

                        .custom-budget {
                            // border: 1px solid red;
                            margin: 0;

                            p {
                                font-weight: 600;
                            }

                            // input {
                            //     width: 100%;
                            //     outline: none;
                            //     border: 1px solid grey;
                            //     border-radius: 5px;
                            //     padding: 0.8rem;
                            // }
                        }
                    }
                }

                .project-posting-login-container {
                    width: 100%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    align-items: center;

                    .text-fileds {
                        width: 90%;
                    }

                    .freelancer-registration-button {
                        width: 30%;

                        background-color: #FF3131;
                        border-radius: 4px;

                        button {
                            width: 100%;
                            background-color: #FF3131;
                            padding: 8px;
                            border-radius: 4px;
                            border: none;
                            outline: none;
                            color: white;
                        }
                    }
                }
                .hire-talent-indutry{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    .hire-talent-industry-inner{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        label {
                            font-weight: 600;
                            font-size: 18px;
                        }
                        input {
                            border-radius: 4px;
                            padding: 8px;
                            outline: none;
                            border: 1px solid grey;
                        }
                    }

                }

            }



            .project-button {
                width: 100%;
                display: flex;
                gap: 1rem;
                justify-content: center;

                button {
                    width: 20%;
                    padding: 7px;
                    background-color: #FF3131;
                    font-weight: 500;
                    color: 23px;
                    font-size: 22px;
                    border: none;
                    outline: none;
                    color: white;
                    border-radius: 6px;
                }
            }
        }

        .global-flex2-job {
            width: 40%;
            height: 100vh;
            // border: 2px solid blue;
            position: fixed;
            right: 0%;
            top: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .global-projectpost {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .global-project-post-container {
            width: 100%;
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .global-flex1 {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding: 2rem;
                gap: 3rem;
                z-index: 1;
                margin-top: 4rem;
                background-color: aliceblue;
                // border: 1px solid red;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;

                .post-project-logo {
                    width: 80%;
                }

                .project-post-heading {
                    width: 90%;

                    // border: 1px solid green;

                    h1 {
                        font-size: 25px;
                        font-weight: 600;
                        // border: 1px solid green;
                    }
                }

                .form-content {
                    width: 90%;

                    // border: 1px solid red;
                    .project-posting-container {
                        width: 100%;

                        h2 {
                            font-size: 22px;
                        }

                        label {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            font-weight: 600;
                            font-size: 14px;
                            margin-top: 1rem;

                            input {
                                border-radius: 4px;
                                padding: 8px;
                                outline: none;
                                border: 1px solid grey;
                            }

                            textarea {
                                padding: 8px;
                                font-size: 15px;
                            }
                        }


                    }

                    .project-posting-category-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        .project-posting-category {
                            label {
                                font-weight: 600;
                                font-size: 14px;
                            }

                            select {
                                border-radius: 4px;
                                padding: 8px;
                                outline: none;
                                border: 1px solid grey;
                            }
                        }
                    }

                    .project-posting-skills-container {
                        width: 100%;

                        .project-posting-skill {

                            display: flex;
                            align-items: center;
                            gap: 8px;
                            width: 100%;
                            flex-wrap: wrap;

                            input {
                                width: 100%;
                                border-radius: 4px;
                                padding: 8px;
                                outline: none;
                                border: 1px solid grey;
                            }

                            .skill-expertise-button {
                                width: 100%;
                                text-align: center;

                                button {
                                    width: 50%;
                                }

                            }
                        }



                        .skill-expertise-data {
                            display: flex;
                            width: 100%;
                            gap: 16px;
                            margin-top: 10px;
                            flex-wrap: wrap;

                            // border: 1px solid red;
                            .software-known-button-conatiner {
                                display: flex;
                                position: relative;



                                .software-konw-icon {
                                    position: absolute;
                                    color: red;
                                    right: -5px;
                                    top: -5px;
                                    font-size: 15px;
                                }
                            }
                        }
                    }

                    .pay-layout {
                        // border: 1px solid red;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        flex-wrap: wrap;

                        .pay-layout-inner {
                            width: 100%;
                            display: flex;
                            align-items: flex-start;
                            gap: 1rem;
                            flex-wrap: wrap;

                            // border: 1px solid green;

                            .pay-hour {
                                width: 100%;
                                padding: 1rem;
                                border: 1px solid rgb(224, 215, 215);
                                height: auto;
                                border-radius: 5px;
                                background-color: white;
                                transition: background-color 0.3s;

                                h6 {
                                    font-weight: 600;
                                }

                                p {
                                    font-size: 15px;
                                }

                                &:hover {

                                    box-shadow: 0 1px 10px #12151b40;
                                }

                                &.selected {
                                    border: 2px solid red;
                                    border-color: red;
                                    /* Change background color when selected */
                                }
                            }


                        }

                        .toggle-budget {
                            // border: 1px solid green;
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                            flex-wrap: wrap;

                            .select-main-heading {
                                font-weight: 600;
                                font-size: 18px;
                            }

                            .per-hour-budget {
                                width: 100%;
                                display: flex;
                                gap: 1rem;
                                flex-wrap: wrap;

                                .custom-budget {
                                    // border: 1px solid red;
                                    margin: 0;
                                    width: 100%;

                                    // display: flex;
                                    p {
                                        font-weight: 600;
                                    }

                                    .input-group {
                                        display: flex;
                                    }

                                    input {
                                        // width: 100%;
                                        outline: none;
                                        // border: 1px solid grey;
                                        // border-radius: 5px;
                                        padding: 0.8rem;
                                    }
                                }
                            }

                            .custom-budget {
                                // border: 1px solid red;
                                margin: 0;

                                p {
                                    font-weight: 600;
                                }

                                // input {
                                //     width: 100%;
                                //     outline: none;
                                //     border: 1px solid grey;
                                //     border-radius: 5px;
                                //     padding: 0.8rem;
                                // }
                            }
                        }
                    }

                    .project-posting-login-container {
                        width: 100%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        align-items: center;

                        .text-fileds {
                            width: 100%;
                        }

                        .freelancer-registration-button {
                            width: 40%;

                            background-color: #FF3131;
                            border-radius: 4px;

                            button {
                                width: 100%;
                                background-color: #FF3131;
                                padding: 8px;
                                border-radius: 4px;
                                border: none;
                                outline: none;
                                color: white;
                            }
                        }
                    }

                }



                .project-button {
                    width: 100%;
                    display: flex;
                    gap: 1rem;
                    justify-content: center;

                    button {
                        width: 40%;
                        padding: 6px;
                        background-color: #FF3131;
                        font-weight: 500;
                        color: 23px;
                        font-size: 18px;
                        border: none;
                        outline: none;
                        color: white;
                        border-radius: 6px;
                    }
                }
            }

            .global-flex2 {
                width: 100%;
                height: 20vh;
                position: fixed;
                right: 0%;
                top: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}