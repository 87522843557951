.footer {
    // margin-top: 1rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    // border: 1px solid red;
    background-color: black;
    border-top-left-radius: -30px;

    .footer-inner-container {
        // border: 1px solid white;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;

        .footer-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            border-bottom: 1px solid white;

            .footer-logo {
                width: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                h5 {
                    font-size: 25px;
                    font-weight: 600;
                    color: white;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #ADADAD;
                }
            }

            .footer-subscribe {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                form {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    gap: 1rem;

                    .subscribe-input {
                        width: 60%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        input {
                            width: 100%;
                            border-radius: 58px;
                            outline: none;
                            border: 1px solid grey;
                            //    padding-left: 2rem;
                            padding: 10px 10px 10px 40px;

                        }
                    }

                    .subscribe-button {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 32px;

                        background-color: #FF3131;

                        button {
                            width: 100%;
                            color: white;
                            border-radius: 32px;
                            padding: 10px;
                        }
                    }
                }
            }
        }

        .footer-category {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: 1rem;

            flex-direction: column;

            .category-heading {
                width: 100%;
                color: white;

            }

            .categories-data {
                color: white;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 10px;
                width: 100%;
                // border: 1px solid white;

                .categories-skill {
                    display: flex;
                    text-transform: capitalize;

                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
            }
        }

        .footer-location {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: wrap;
            padding: 1rem;

            flex-direction: column;

            .location-heading {
                width: 100%;
                color: white;
            }
            .footer-choose-location{
                width: 100%;
                display: flex;
                align-items: flex-start;
                gap: 5px;
                justify-content:center;
                flex-direction: column;
                margin-bottom: 1rem;
                label{
                    color: white;
                }
                select{
                    width: 25%;
                }
            }

            .location-data {
                color: white;
                display: flex;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;

                .location-city {
                    color: white;
                    text-transform: capitalize;
                    text-decoration: none       ;
                }
            }
        }

        .footer-about-section {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 2rem;
            color: white;
            padding: 1rem;


            .footer-about-links1 {
                width: 25%;

                h5 {
                    color: white;
                    // width: 70%;
                    // border-bottom: 2px solid white;
                }

                p {
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
            }

            .footer-about-links2 {
                width: 25%;

                h5 {
                    color: white;
                    // width: 40%;
                    // border-bottom: 2px solid white;
                }

                p {
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
            }

            .footer-about-links3 {
                width: 25%;

                h5 {
                    color: white;
                    width: 80%;
                    // border-bottom: 2px solid white;
                }

                p {
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
            }

            .footer-about-links4 {
                width: 25%;

                h5 {
                    color: white;
                    // width: 70%;
                    // border-bottom: 2px solid white;
                }

                p {
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
            }

            // .footer-about-links5 {
            //     width: 20%;

            //     h5 {
            //         color: white;
            //         // width: 50%;
            //         // border-bottom: 2px solid white;
            //     }

            //     p {
            //         a {
            //             text-decoration: none;
            //             color: white;
            //         }
            //     }
            // }
        }

        .footer-social-links {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 1rem;
            margin-bottom: 1rem;

            .social-links-flex1 {
                width: 20%;
                color: white;

            }

            .social-links-flex2 {
                width: 100%;
                color: #ADADAD;
                display: flex;
                justify-content: center;
                // border: 1px solid white;
                flex-wrap: wrap;

                h6 {
                    font-size: 16px;
                    font-weight: 400;
                    text-align: center;
                }

            }

            .social-links-flex3 {
                width: 25%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 20px;
                // border: 1px solid white;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .footer {
        margin-top: 1rem;
      
     

        .footer-inner-container {
            // border: 1px solid white;
            width: 100%;
            .footer-container {
               
                flex-wrap: wrap;

                .footer-logo {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: column;

                    h5 {
                        font-size: 22px;
                        font-weight: 600;
                        color: white;
                    }

                    p {
                        text-align: center;
                        font-size: 13px;
                        font-weight: 400;
                        color: #ADADAD;
                    }
                }

                .footer-subscribe {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    form {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        gap: 1rem;

                        .subscribe-input {
                            width: 60%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            input {
                                width: 100%;
                                border-radius: 58px;
                                outline: none;
                                border: 1px solid grey;
                                //    padding-left: 2rem;
                                padding: 10px 10px 10px 10px;
                                font-size: 13px;

                            }
                        }

                        .subscribe-button {
                            width: 30%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 32px;

                            background-color: #FF3131;

                            button {
                                width: 100%;
                                color: white;
                                border-radius: 32px;
                                padding: 6px;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }

            .footer-category {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding: 1rem;

                flex-direction: column;

                .category-heading {
                    width: 100%;
                    color: white;
                    text-align: center;
                }

                .categories-data {
                    width: 100%;
                    color: white;
                    text-align: center;
                    // display: flex;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .categories-skill {
                        text-align: center;

                        text-transform: capitalize;
                    }
                }
            }

            .footer-location {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding: 1rem;

                flex-direction: column;
                select{
                    width: 100%;
                }
                .location-heading {
                    width: 100%;
                    color: white;
                    text-align: center;

                }
                .footer-choose-location{
                    width: 100%;
                    
                    align-items: center;
                    
                    label{
                        color: white;
                    }
                    select{
                        width: 90%;
                    }
                }
    
                 

                .location-data {
                    width: 100%;
                    color: white;
                    text-align: center;
                  
                    flex-wrap: wrap;
                    justify-content: center;

                    .location-city {
                        text-transform: capitalize;
                    }
                }
            }

            .footer-about-section {
                width: 100%;
                 
                border-bottom: 1px solid white;
                flex-wrap: wrap;

                .footer-about-links1 {
                    width: 100%;
                    text-align: center;

                    h3 {
                        color: white;
                    }

                    p {
                        a {
                            text-decoration: none;
                            color: white;
                        }
                    }
                }

                .footer-about-links2 {
                    width: 100%;
                    text-align: center;


                    h3 {
                        color: white;
                    }

                    p {
                        a {
                            text-decoration: none;
                            color: white;
                        }
                    }
                }

                .footer-about-links3 {
                    width: 100%;
                    text-align: center;

                    h3 {
                        color: white;
                    }

                    p {
                        a {
                            text-decoration: none;
                            color: white;
                        }
                    }
                }

                .footer-about-links4 {
                    width: 100%;
                    text-align: center;

                    h3 {
                        color: white;
                    }

                    p {
                        a {
                            text-decoration: none;
                            color: white;
                        }
                    }
                }

                .footer-about-links5 {
                    width: 100%;
                    text-align: center;

                    h3 {
                        color: white;
                    }

                    .footer-email {
                        font-size: 13px;
                    }

                    p {

                        color: white;
                        text-decoration: none;

                    }
                }
            }

            .footer-social-links {
                
                flex-wrap: wrap;
              
                 
                gap: 1rem;

                .social-links-flex1 {
                    width: 100%;
                    color: white;
                    text-align: center;
                }

                .social-links-flex2 {
                    width: 100%;
                    
                    gap: 20px;

                    h6 {
                        font-size: 13px;
                    }
                }

                .social-links-flex3 {
                    width: 100%;
                    // border: 1px solid white;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}