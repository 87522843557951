.bidonproject{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1rem;
    .bidonproject-container{
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
        margin-top: 10px;
        flex-direction: column;
        .propsal-setting{
            width: 100%;
            border-radius: 12px;
            border: 1px solid rgb(206, 203, 203);
            padding: 1rem;
            display: flex;
            box-shadow: 3px 3px 3px rgb(229, 224, 224);
            justify-content: space-between;
            background-color: rgb(232, 93, 47);
            h3{
                font-weight: 600;
                font-size: 20px;
                color: white;
                span{
                    font-size: 15px;
                    font-weight: 400;
                    margin-top: 1rem;
                }
            }
        }
         .how-paid{
            margin-top: 1rem;
            width: 100%;
           border-bottom: 1px solid rgb(190, 187, 187);
          padding-bottom: 1rem;
           .form-check{
            input[type="radio"] {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                appearance: none;
                border: 2px solid grey;
                outline: none;
                transition: border-color 0.3s, background-color 0.3s;
                background-color: white;
            }
            input[type="radio"]:checked {
                border-color: red; /* Change the border color to red when selected */
                background-color: red; /* Change the background color to red when selected */
            }
            input[type="radio"]:focus {
                border-color: red; /* Optional: make the border blue on focus */
            }
            label{
                font-weight: 500;
            }
            p{
                font-size: 14px;
                font-weight: 400;
                color: grey;
            }
            
           }
         }
         .mile-stone-include{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .milestone-row{
                width: 100%;
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                .description-input {
                    flex: 0 0 42%; /* Takes up 35% of the row */
                    padding: 10px;
                    border-radius: 10px;
                    outline: none;
                    border: 1px solid grey;
                  }
                  .date-input {
                    flex: 0 0 25%; /* Takes up 20% of the row */
                    padding: 10px;
                    border-radius: 10px;
                    outline: none;
                    border: 1px solid grey;
                  }
              
                  .amount-input {
                    flex: 0 0 25%; /* Takes up 20% of the row */
                    padding: 10px;
                    border-radius: 10px;
                    outline: none;
                    border: 1px solid grey;
                  }
                  .error {
                    border: 1px solid red;
                }
                
                .error-message {
                    width: 92%;
                    // border: 1px solid red;
                    text-align: end;
                    color: red;
                    font-size: 16px;
                    // display: flex;
                    // justify-content: center;
                    margin-top: 0px;
                    // padding-right: 10px;
                }
              
                  .remove-milestone-button {
                    flex: 0 0 5%; /* Takes up 5% of the row */
                    background-color: transparent;
                    color: red;
                    font-size: 22px;
                    border: none;
                    cursor: pointer;
                  }
    
            }
            .add-milestone-button{
                width: 20%;
                padding: 10px;
                font-weight: 500;
                border-radius: 10px;
                font-size: 17px;
                color: blue;
                text-align: start;
                border: none;
                background-color: transparent;
            }
         }
         .total-price{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-top: 1rem;
            border-bottom: 1px solid grey;
            padding-bottom: 2rem;
            .total-price-inner{
                width: 100%;
                display: flex;
                
                justify-content: space-between;
               
                h6{
                    font-weight: 500;
                    span{
                        font-weight: 400;
                        font-size: 14px;
                        color: rgb(72, 70, 70);
                    }
                }
            }
         }
         .bid-for-project{
            margin-top: 1rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            border-bottom: 1px solid grey;
            padding-bottom: 2rem;
            .bid-for-project-inner{
                width:100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                h6{
                    font-weight: 500;
                    span{
                        color: rgb(91, 89, 89);
                        font-size: 15px;
                        font-weight: 400;
                    }
                }
                input{
                    text-align: end;
                    padding: 7px;
                }
            }
         }
         .take-project{
            margin-top: 1rem;
            width: 100%;
            border-bottom: 1px solid grey;
            padding-bottom: 2rem;
            select{
                width: 30%;
                margin-top: 1rem;
                padding: 10px;
            }
         }
         .additional-detail{
            width: 100%;
            h6{
                margin-top: 1rem;
                color: rgb(53, 48, 48);
                display: flex;
                justify-content: space-between;
                span{
                    font-weight: 500;
                    font-size: 16px;
                    color :green;
                    cursor: pointer;
                }
            }
            textarea{
                width: 100%;
                border-radius: 3px;
                outline: none;
                padding: 1rem;
            }
         }
       
    }
}
@media (max-width: 575.98px) {
    .bidonproject{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 1rem;
        .bidonproject-container{
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 1rem;
            margin-top: 10px;
            flex-direction: column;
            .propsal-setting{
                width: 100%;
                border-radius: 12px;
                border: 1px solid rgb(206, 203, 203);
                padding: 1rem;
                display: flex;
                box-shadow: 3px 3px 3px rgb(229, 224, 224);
                justify-content: space-between;
                background-color: rgb(232, 93, 47);
                h3{
                    font-weight: 600;
                    font-size: 16px;
                    color: white;
                    span{
                        font-size: 15px;
                        font-weight: 400;
                        margin-top: 1rem;
                    }
                }
            }
             .how-paid{
                margin-top: 1rem;
                width: 100%;
               border-bottom: 1px solid rgb(190, 187, 187);
              padding-bottom: 1rem;
               .form-check{
                input[type="radio"] {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    appearance: none;
                    border: 2px solid grey;
                    outline: none;
                    transition: border-color 0.3s, background-color 0.3s;
                    background-color: white;
                }
                input[type="radio"]:checked {
                    border-color: red; /* Change the border color to red when selected */
                    background-color: red; /* Change the background color to red when selected */
                }
                input[type="radio"]:focus {
                    border-color: red; /* Optional: make the border blue on focus */
                }
                label{
                    font-weight: 500;
                }
                p{
                    font-size: 14px;
                    font-weight: 400;
                    color: grey;
                }
                
               }
             }
             .mile-stone-include{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .milestone-row{
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    .description-input {
                        flex: 0 0 90%; /* Takes up 35% of the row */
                        padding: 10px;
                        border-radius: 10px;
                        outline: none;
                        border: 1px solid grey;
                      }
                      .date-input {
                        flex: 0 0 90%; /* Takes up 20% of the row */
                        padding: 10px;
                        border-radius: 10px;
                        outline: none;
                        border: 1px solid grey;
                      }
                  
                      .amount-input {
                        flex: 0 0 90%; /* Takes up 20% of the row */
                        padding: 10px;
                        border-radius: 10px;
                        outline: none;
                        border: 1px solid grey;
                      }
                      .error {
                        border: 1px solid red;
                    }
                    
                    .error-message {
                        width: 92%;
                        // border: 1px solid red;
                        text-align: end;
                        color: red;
                        font-size: 16px;
                        // display: flex;
                        // justify-content: center;
                        margin-top: 0px;
                        // padding-right: 10px;
                    }
                  
                      .remove-milestone-button {
                        flex: 0 0 5%; /* Takes up 5% of the row */
                        background-color: transparent;
                        color: red;
                        font-size: 22px;
                        border: none;
                        cursor: pointer;
                      }
        
                }
                .add-milestone-button{
                    width: 100%;
                    padding: 10px;
                    font-weight: 500;
                    border-radius: 10px;
                    font-size: 17px;
                    color: blue;
                    text-align: start;
                    border: none;
                    background-color: transparent;
                }
             }
             .total-price{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin-top: 1rem;
                border-bottom: 1px solid grey;
                padding-bottom: 2rem;
                .total-price-inner{
                    width: 100%;
                    display: flex;
                    
                    justify-content: space-between;
                   
                    h6{
                        font-weight: 500;
                        span{
                            font-weight: 400;
                            font-size: 14px;
                            color: rgb(72, 70, 70);
                        }
                    }
                }
             }
             .bid-for-project{
                margin-top: 1rem;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                border-bottom: 1px solid grey;
                padding-bottom: 2rem;
                .bid-for-project-inner{
                    width:60%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    
                    h6{
                        font-weight: 500;
                        span{
                            color: rgb(91, 89, 89);
                            font-size: 15px;
                            font-weight: 400;
                        }
                    }
                    input{
                        text-align: end;
                        padding: 7px;
                    }
                }
             }
             .take-project{
                margin-top: 1rem;
                width: 100%;
                border-bottom: 1px solid grey;
                padding-bottom: 2rem;
                select{
                    width: 100%;
                    margin-top: 1rem;
                    padding: 10px;
                }
             }
             .additional-detail{
                width: 100%;
                h6{
                    margin-top: 1rem;
                    color: rgb(53, 48, 48);
                }
                textarea{
                    width: 100%;
                    border-radius: 3px;
                    outline: none;
                    padding: 1rem;
                }
             }
           
        }
    }
}