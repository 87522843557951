* {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.registation-continer {
    width: 100%;
    // border: 1px solid black;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0%;
    margin: 0%;

    .registation-freelancer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid red;
        padding: 0%;
        margin: 0%;

        .login-flex-first {
            width: 50%;
            min-height: 100vh;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            padding: 2rem;

            // border: 1px solid red;
            // #FF3131
            .freelancer-logo-login {
                width: 100%;
                text-align: end;
                margin-top: 2rem;
                // border: 1px solid red;
                a {
                    img {
                        width: 70px;
                    }
                }
            }

            .login-flex-first-continer {
                margin-top: 2rem;

                // box-shadow: 2px 6px 20px 0 rgba(255, 49, 49, 0.2);
                border-radius: 20px;
                width: 100%;
                min-height: 70vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 0rem;
                // border: 1px solid red;
                .login-flag {
                    width: 90%;
                    // border: 1px solid blue;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 8px;
                    margin-top: 2rem;
                    // padding: 3px;
                    // border-radius: 10px;

                   
.country-flag-regin{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;

    select {
        width: 100%;
        border: none;
        box-shadow: none;
        outline: none;
        display: flex;
        color: grey;
    border-radius: 10px;
background-color: none;
        align-items: center;
        option{
            display: flex;
        }

    }
}
                    
                }

                h4 {
                    font-weight: 500;
                }

                h6 {
                    font-weight: 400;
                    color: grey;
                }

                // border: 1px solid blue;
                .text-fieldss {
                    min-width: 90%;
                    // border: 1px solid green;
                    border-radius: 14px;
                    margin-top: 1.5rem;
                    // #FF3131
                }
                

                .otp-sent-freelancer {
                    margin-top: 1.5rem;
                }

                .freelancer-registration-button {
                    width: 90%;
                    margin-top: 1.5rem;
                    border-radius: 14px;
                    background-color: #FF3131;
                    color: whitesmoke;
                    display: flex;
                    align-items: center;

                    button {
                        width: 100%;
                        padding: 17px 20px;
                        border: none;
                        color: white;
                        background-color: #FF3131;
                        border-radius: 14px;
                    }
                }

            }

        }

        .login-flex-second {
            width: 50%;
            min-height: 100vh;
            display: flex;

            justify-content: center;
            align-items: center;
            // border: 1px solid green;

            .login-flex-second-continer {
                width: 100%;
                min-height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;

                // border: 1px solid green;
                img {
                    width: 100%;
                    height: 100vh;
                    object-fit: cover;
                }


            }

        }
    }

}

@media (max-width: 575.98px) {
    .registation-continer {
        width: 100%;
        // border: 1px solid black;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .registation-freelancer {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            // border: 1px solid red;

            .login-flex-first {
                width: 95%;
                min-height: 100vh;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;

                // border: 1px solid red;
                // #FF3131
                .freelancer-logo-login {
                    width: 100%;
                    text-align: end;
                    margin-top: 2rem;

                    a {
                        img {
                            width: 70px;
                        }
                    }
                }

                .login-flex-first-continer {
                    margin-top: 2rem;

                    // box-shadow: 2px 6px 20px 0 rgba(255, 49, 49, 0.2);
                    border-radius: 20px;
                    width: 100%;
                    min-height: 70vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 0rem;

                    h4 {
                        font-weight: 500;
                    }

                    h6 {
                        font-weight: 400;
                        color: grey;
                    }

                    // border: 1px solid blue;
                    .text-fileds {
                        width: 100%;
                        // border: 1px solid green;
                        border-radius: 14px;
                        margin-top: 1.5rem;
                        // #FF3131
                    }

                    .freelancer-registration-button {
                        width: 100%;
                        margin-top: 1.5rem;
                        border-radius: 14px;
                        background-color: #FF3131;
                        color: whitesmoke;
                        display: flex;
                        align-items: center;

                        button {
                            width: 100%;
                            padding: 17px 20px;
                            border: none;
                            color: white;
                            background-color: #FF3131;
                            border-radius: 14px;
                        }
                    }

                }

            }

            .login-flex-second {
                width: 50%;
                min-height: 100vh;
                display: none;



            }
        }

    }
}